import axios from "../../../axios/index.js"


// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

const domain = process.env.VUE_APP_LOGIN_DOMAIN;
//const domain = "http://localhost:10443"
//const domain = "http://35.198.161.16:81";

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}



export default {
  init() {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch("auth/fetchAccessToken")
            .then((access_token) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            resolve(axios(originalRequest))
          })
        })


        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login(userData) {

    //axios.defaults.headers['Authorization'] = "";
    axios.defaults.headers.header['Authorization'] = "";
    console.log(axios.defaults.headers.header['Authorization'])
    return axios.post(domain+"/auth/authenticate", userData)
  },

  getRolePermissions(role) {
    axios.defaults.headers['Authorization'] = "Bearer "+localStorage.getItem("accessToKen");
    return axios.get(domain+"/auth/getPermissions/"+role)
  },

  forgotPassword(payload) {
    axios.defaults.headers['Authorization'] = "";
    return axios.post(domain+"/auth/forgotPassword", payload)
  },
  resetPasswordViaEmail(userData){
    axios.defaults.headers['Authorization'] = "";
    return axios.post(domain+"auth/resetPassword", userData);
  },

  refreshToken() {
    return axios.post("/api/auth/refresh-token", {accessToken: localStorage.getItem("accessToKen")})
  }
}
